import * as React from "react"
import ReactMapGL, { Marker, InteractiveMapProps } from "react-map-gl"
import StickyHeader from "../components/sticky-header";
import { Spinner } from "../components/spinner";
import { graphql } from "gatsby";
import { PrismicLocation, PrismicLocationData } from "../../graphql-types"
import { ContentSideCard } from "../components/contentSideCard";
import { ContentCard } from "../components/contentCard";
import { ContentComponent } from "../components/contentComponent";
import GlobalContext from "../context/globalContext";

import "./map.scss";

interface IProps {
  allPrismicLocation: {
    nodes: PrismicLocation[]
  }
}


const MapView: React.FC<{ data: IProps }> = ({ data }) => {
  const { isMobileSize } = React.useContext(GlobalContext)
  const mapWrapper: React.MutableRefObject<HTMLDivElement> = React.useRef(null)
  const mapPage: React.MutableRefObject<HTMLDivElement> = React.useRef(null)
  const [currentCard, setCurrentCard] = React.useState<string>()
  const [userLocation, setUserLocation] = React.useState<Coordinates>()
  const [userLocationID, setUserLocationID] = React.useState<number>()
  const [loaded, setLoaded] = React.useState<boolean>(false)

  const [cardOpen, setCardOpen] = React.useState(false)

  const locations = data.allPrismicLocation.nodes;

  const mapWrapperSize: { width: string; height: string } = React.useMemo(() => {
    if (mapWrapper.current) {
      return (({ width, height }) => ({ width, height }))(window.getComputedStyle(mapWrapper.current))
    }
    return { width: null, height: null }
  }, [mapWrapper.current])

  const [mapParams, setMapParams] = React.useState<Partial<InteractiveMapProps>>({
    mapboxApiAccessToken: "pk.eyJ1IjoidGVkeC1iYXRoIiwiYSI6ImNqemUxZXA2czBrZGUzam1hMWFvdWF6anQifQ.rWjaatf4UM5QxQI6VVYOqw",
    latitude: 51.3801251,
    longitude: -2.3720824,
    mapStyle: "mapbox://styles/tedx-bath/cjze4wzmq01gf1doxly8izj4k/draft",
    zoom: 12,
    onViewportChange: (viewport) => {
      const { latitude, longitude, zoom } = viewport
      setMapParams({ ...mapParams, latitude, longitude, zoom })
    },
    onLoad: () => setLoaded(true)
  })

  function handleLocation() {
    if (userLocationID) {
      navigator.geolocation.clearWatch(userLocationID)
      setUserLocation(undefined)
      setUserLocationID(undefined)
      console.log(userLocation)
    } else {
      const id = navigator.geolocation.watchPosition((position) => setUserLocation(position.coords))
      setUserLocationID(id)
      console.log(userLocation)
    }
  }

  const locationButtonText = React.useMemo(() => {
    if (userLocationID) {
      if (userLocation) {
        return <span className="location-found">You are here<img src={require('../assets/images/you-are-here.svg')}/></span>
        // return "Turn off your geolocation"
      } else {
        return ". . ."
      }
    } else {
      return "Find your location?"
    }
  }, [userLocationID, userLocation])

  return (
    <div className="map-page" data-card-open={cardOpen}>
      <StickyHeader previousPage="home" />
      {!loaded && <Spinner />}
      <button className="location-button" onClick={handleLocation}>{locationButtonText}</button>
      <div className="map-wrapper" ref={mapWrapper}>
        <ReactMapGL {...mapParams} {...mapWrapperSize}>
          {locations.map(location => {
            return <div onClick={() => setCurrentCard(location.uid)}>
              <Marker {...location.data.geolocation as any} className={`pin-${location.uid}`} />
            </div>
           })}
           </ReactMapGL>
         </div>
         {isMobileSize == false ? 
         <>
         {!!currentCard && <ContentSideCard cardTitle={data.allPrismicLocation.nodes.find(node => node.uid === currentCard).data.title.text} open={cardOpen} setOpen={setCardOpen} prismicLocation={data.allPrismicLocation.nodes.find(node => node.uid === currentCard)} />  }
         </>
         : 
         <>
         {!!currentCard && <ContentCard open={cardOpen} setOpen={setCardOpen} content={data.allPrismicLocation.nodes.find(node => node.uid === currentCard)} />}
         </>
         }
       </div>
     )
   }
   

export default MapView

export const pageQuery = graphql`
query Locations {
  allPrismicLocation {
    nodes {
      uid
      data {
        title {
          text
        }
        header_image {
          url
        }
        geolocation {
          latitude
          longitude
        }
        past {
          html
        }
        present {
          html
        }
        speaker_social_media {
          url
          text
          linkTo {
            url
          }
        }
        future {
          html
        }
      }
    }
  }
}
`

