//side card for desktop sizes

import './contentSideCard.scss';
import * as React from "react"
import { ContentComponent } from './contentComponent';
import { PrismicLocation } from '../../graphql-types';

interface IProps {
  prismicLocation?: PrismicLocation
  open: boolean
  setOpen: (open: boolean) => void
  cardTitle: string
}

export const ContentSideCard: React.FC<IProps> = props => {
console.log(props.cardTitle)
  return (
    <div className="desktop-side-bit" data-open={props.open}> 
      <input type="checkbox" name="card-opener" className="card-opener"/>
      <span className="preview-title">{props.cardTitle}</span>
      <div className="content-component">
        <ContentComponent prismicLocation={props.prismicLocation} /> 
      </div>
    </div>
  )
}

