import './contentCard.scss';
import * as React from "react"
import Swiper, { ReactIdSwiperProps, SwiperInstance } from "react-id-swiper"
import { ContentComponent } from './contentComponent';
import { PrismicLocation } from '../../graphql-types';

interface IProps {
  content?: PrismicLocation
  open: boolean
  setOpen: (open: boolean) => void
}

export const ContentCard: React.FC<IProps> = props => {

  const [swiper, setSwiper] = React.useState<SwiperInstance>()
  const [eventsHook, setEventsHook] = React.useState(false)

  const swiperParams1: ReactIdSwiperProps = {
    direction: "vertical",
    containerClass: "map-item-swiper",
    resistanceRatio: 0,
    autoHeight: true,
    slidesPerView: 'auto',
    getSwiper: (swiper) => setSwiper(swiper)
  }

  React.useEffect(() => {
    if (swiper && !eventsHook) {
      setEventsHook(true)
      swiper.on("transitionStart", () => props.setOpen(swiper["swipeDirection"] === "next"))

      return () => {
        swiper.off("transitionStart")
      }
    }
  }, [swiper])

  return (
    <Swiper {...swiperParams1}>
      <div className={props.open === true ? "open content" : "content "} data-open={props.open}>
        <ContentComponent prismicLocation={props.content}/>
      </div>
      <div className="wowzers" />
    </Swiper>
  )
}